.ShowcaseContainer {
  width: 65%;
  height: fit-content;
  min-height: 500px;
  /* background-color: var(--dark-background-color); */
  margin-top: 25px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem 3rem;
  border-radius: 30px;
  background: rgb(35, 36, 40);
  background: linear-gradient(
    144deg,
    var(--background-color) 0%,
    var(--dark-background-color) 100%
  );
  box-shadow: 2px 2px 8px 5px rgba(0, 0, 0, 0.3);
}

@media only screen and (max-width: 1024px) {
  .ShowcaseContainer {
    width: 85vw;
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .ShowcaseContainer {
    width: 90vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .ProjectShowcaseInfoContainer {
    height: fit-content !important;
  }

  .ProjectShowcaseInfoContainer p {
    height: 40%;
  }

  .ProjectShowcaseInfoContainer .ProjectTitle {
    margin-top: 20px;
    width: 90%;
  }
}

.ProjectShowcaseInfoContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  height: fit-content;
}

.ProjectShowcaseInfoContainer h1 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.ProjectShowcaseInfoContainer h4 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.ProjectShowcaseInfoContainer p {
  overflow-y: auto;
}

.ProjectShowcaseInfoContainer div {
  font-size: 1.2rem;
}

.ShowcaseRowContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ExpandleChipContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  row-gap: 0.75rem;
}

.ShowcaseRowItem {
  margin-left: 12px;
  color: var(--secondary-color);
}

.ProjectTitle {
  font-size: 1.6rem;
}
