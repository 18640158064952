.JobCardContainer {
  width: 65vw;
  min-width: 370px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: rgb(35, 36, 40);
  background: linear-gradient(
    135deg,
    var(--background-color) -30%,
    var(--dark-background-color) 100%
  );
  border-radius: 16px;
  padding: 2rem 2rem;
  text-align: left;
  margin-bottom: 2rem;
  box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.3);
}

.CollapseButton {
  background-color: rgba(0, 0, 0, 0);
  width: 40px;
  height: 40px;
  color: var(--text-color);
  border: 2px;
  border-style: solid;
  border-color: white;
  margin-left: 10px;
  border-radius: 6px;
}

.JobCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
  width: 100%;
}

.JobMainInfoContainer {
  display: flex;
  flex-direction: column;
}

.JobSecondaryInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.JobSecondaryInfoContainer > h4 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.JobMainInfoContainer > h2 {
  color: var(--primary-color);
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.9rem;
}

.JobMainInfoContainer > h3 {
  color: var(--text-color);
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1.5rem;
}

.JobCardContent {
  overflow: hidden;
}

.JobCardContent.Expanded {
  display: flex;
  flex-direction: column;
  max-height: 600px;
  transition: max-height 0.8s ease;
}

.JobCardContent.Collapsed {
  max-height: 0;
  transition: max-height 0.5s ease;
}

.ResumeSkillsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  row-gap: 0.75rem;
  margin-top: 10px;
}

.JobCardContent > ul {
  list-style-type: "-  ";
}

.JobCardContent > ul > li {
  margin-bottom: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .JobCardContainer {
    width: 70vw;
  }
  .JobCardContent.Expanded {
    max-height: 800px;
  }
}

@media only screen and (max-width: 767px) {
  .JobCardContainer {
    width: 85vw;
    padding: 8px;
  }

  .JobCardContent.Expanded {
    max-height: 1200px;
  }

  .JobMainInfoContainer > h2 {
    color: var(--primary-color);
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 1.35rem;
  }

  .JobMainInfoContainer > h3 {
    color: var(--text-color);
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1.1rem;
  }

  .JobSecondaryInfoContainer > h4 {
    font-size: 1rem;
  }
}
