.Chip {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  border-radius: 20px;
  min-width: 50px;
  min-height: fit-content;
  height: 35px;
  overflow: hidden;
  min-width: fit-content;
}

.Chip.Clickable:hover {
  background-color: var(--secondary-color);
  cursor: pointer;
  box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.4);
}

.ChipText {
  padding: 0.5rem 0.75rem;
  color: white;
  white-space: nowrap;
  margin: 0px;
  font-size: 0.9rem !important;
  font-weight: 500;
}
