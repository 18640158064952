:root {
  --primary-color: #47d653;
  --secondary-color: #45a950;
  --background-color: #26292d;
  --dark-background-color: #1c1d22;
  --text-color: #ffffff;
  --app-background-color: #292a30;
}

.App {
  text-align: center;
  color: var(--text-color);
  background-color: var(--app-background-color);
  height: fit-content;
  padding-bottom: 5vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.App-link {
  color: #61dafb;
}

.MainSectionHeader {
  text-align: left;
  font-size: 3rem;
  color: var(--primary-color);
}
