.ContactCardContainer {
  width: 50vw;
  min-width: fit-content;
  height: 40%;
  /* background-color: var(--dark-background-color); */
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 30vh;
  padding: 1rem 2rem;
  border-radius: 30px;
  background: rgb(35, 36, 40);
  background: linear-gradient(
    144deg,
    var(--background-color) -30%,
    var(--dark-background-color) 100%
  );
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.3);
}

.ContactCardContainer h2 {
  text-align: left;
  font-size: 1.5rem;
  color: var(--primary-color);
}

.ContactRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ContactRow h3 {
  font-size: 1.2rem;
  color: var(--secondary-color);
  text-decoration: none;
  margin-right: 20px;
}

.ContactRow a {
  text-decoration: none;
  color: var(--text-color);
}
