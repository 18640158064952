.ProjectCard {
  width: 100%;
  height: 90%;
  border-radius: 10px;
  /* background-color: var(--dark-background-color);*/
  background: rgb(35, 36, 40);
  background: linear-gradient(
    135deg,
    var(--background-color) 0%,
    var(--dark-background-color) 100%
  );
  overflow: hidden;
  text-align: left;
  padding-bottom: 10px;
  box-shadow: 2px 2px 8px 4px rgba(0, 0, 0, 0.3);
}

.ProjectCardSelected {
  width: 100%;
  height: 90%;
  border-radius: 10px;
  /* background-color: var(--dark-background-color);
   */
  background: rgb(35, 36, 40);
  background: linear-gradient(
    135deg,
    var(--background-color) 0%,
    var(--dark-background-color) 100%
  );
  overflow: hidden;
  text-align: left;
  padding-bottom: 10px;
  outline: solid 5px var(--secondary-color);
  box-shadow: 4px 4px 8px 8px rgba(0, 0, 0, 0.3);
}

.ProjectCard:hover {
  cursor: pointer;
}

.ProjectCardSelected:hover {
  cursor: pointer;
}

.ProjectCard p {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.ProjectCardSelected p {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.ProjectThumbnailContainer {
  width: 100%;
  height: 55%;
  overflow: hidden;
}

.ProjectThumbnailContainer img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.ProjectInfoContainer {
  height: 42%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
}

.ProjectInfoContainer h2 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.TechnologiesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: auto;
  height: fit-content;
}

.TechnologyTag {
  height: fit-content;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-right: 10px;
  font-weight: 800;
  color: var(--secondary-color);
}
