.ProjectGallery {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.ProjectCardContainer {
  display: grid;
  width: fit-content;
  grid-template-columns: 25vw 25vw 25vw;
  grid-template-rows: auto;
  grid-column-gap: 2vw;
  grid-row-gap: 3vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

@media only screen and (min-width: 1024px) and (max-width: 1500px) {
  .ProjectCardContainer {
    grid-template-columns: 40vw 40vw;
    margin-left: 4vw;
  }
}

@media only screen and (max-width: 1024px) {
  .ProjectCardContainer {
    display: flex;
    flex-direction: column;
    width: 95vw;
    max-width: 600px;
    align-items: center;
  }

  .ProjectGallery {
    margin-right: auto;
    margin-left: auto;
    width: 95vw;
    display: flex;
    flex-direction: column;
  }

  .MainSectionHeader {
    text-align: center;
  }
}
