.MediaContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 25vw;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.MediaButton {
  background-color: var(--primary-color);
  height: 40px !important;
  width: 40px !important;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border-width: 0px;
  color: var(--text-clor);
}

.MediaButton:hover {
  background-color: var(--secondary-color);
  cursor: pointer;
}

.VideoPlayer {
  margin: 5px;
  width: calc(100% - 90px);
  height: 100%;
  min-height: 200px;
}

.ImageViewer {
  margin: 5px;
  height: 100%;
  width: calc(100% - 90px);
  min-height: 200px;
}

@media only screen and (max-width: 767px) {
  .ProjectShowcase .MediaCarousel {
    height: 100%;
    width: 100%;
    min-width: 300px;
  }

  .MediaButton {
    width: 30px;
    height: 30px;
  }
  .MediaContainer {
    height: auto;
    width: 100%;
  }

  .VideoPlayer {
    height: auto;
    width: calc(100% - 70px);
  }

  .ImageViewer {
    height: auto;
    width: calc(100% - 70px);
  }
}
